import OptionGenericLogic from "../utils/OptionGenericLogic";

function OptionTwo() {
  return (
    <div>
      <OptionGenericLogic option="OptionTwo" />
    </div>
  );
}

export default OptionTwo;
