import OptionGenericLogic from "../utils/OptionGenericLogic";

function OptionThree() {
  return (
    <div>
      <OptionGenericLogic option="OptionThree" />
    </div>
  );
}

export default OptionThree;
