import OptionGenericLogic from "../utils/OptionGenericLogic";

function OptionOne() {
  return (
    <div>
      <OptionGenericLogic option="OptionOne" />
    </div>
  );
}

export default OptionOne;
